
/*-----------------
	19. Chat
-----------------------*/

.chat-avatar-sm {
    display: inline-block;
    float: left;
    margin-left: 0 !important;
    margin-right: 10px;
    position: relative;
    width: 24px;
}
.chat-avatar-sm img {
	width:24px;
}
.chat-avatar-sm.user-img .status {
	bottom: 0;
	right: -3px;
}
.chat-main-row {
	bottom: 0;
	left: 0;
	overflow: auto;
	padding-bottom: inherit;
	padding-top: inherit;
	position: absolute;
	right: 0;
	top: 0;
}
.chat-main-wrapper {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
}
.message-view {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
	width: 75%;
}
.chat-window {
	display: table;
	height: 100%;
	table-layout: fixed;
	width: 100%;
	background-color: #f7f7f7;
}
.fixed-header {
	background-color: #fff;
	border-bottom: 1px solid #eaeaea;
	padding: 10px 15px;
}
.fixed-header .navbar {
	border: 0 none;
	margin: 0;
	min-height: auto;
	padding: 0;
}
.fixed-header .user-info a {
	color: #555;
	text-transform: uppercase;
}
.typing-text {
	color: #00bcd4;
	font-size: 12px;
	text-transform: lowercase;
}
.last-seen {
	color: #888;
	display: block;
	font-size: 12px;
}
.custom-menu {
	margin-top: 6px;
}
.fixed-header .custom-menu {
	margin: 0 0 1px;
}
.custom-menu.nav > li > a {
    color: #bbb;
    font-size: 26px;
    line-height: 32px;
    margin-left: 15px;
    padding: 0;
}
.custom-menu.navbar-nav > li > a:hover,
.custom-menu.navbar-nav > li > a:focus {
	background-color: transparent;
}
.custom-menu .dropdown-menu {
	left: auto;
	right: 0;
}
.search-box {
	margin-top: 5px;
}
.search-box .input-group {
    background-color: #f7f7f7;
    border: 1px solid #e3e3e3;
    border-radius: 50px;
    padding: 0 10px 0 16px;
    font-size: 16px;
}
.search-box .input-group .form-control {
	background: transparent;
	border: none;
}
.search-box .btn {
	background-color: transparent;
	padding: 0 20px;
}
.search-input {
	border-radius: 5px;
}
.chat-contents {
	display: table-row;
	height: 100%;
}
.chat-content-wrap {
	height: 100%;
	position: relative;
	width: 100%;
}
.chat-wrap-inner {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
}
.chats {
	padding: 30px 15px;
}
.chat-body {
	display: block;
	margin: 10px 0 0;
	overflow: hidden;
}
.chat-body:first-child {
	margin-top: 0
}
.chat-right .chat-content {
    background-color: #f2f2f2;
    border: 1px solid #e3e3e3;
    border-radius: 20px 2px 2px 20px;
    clear: both;
    color: #727272;
    display: block;
    float: right;
    margin: 0;
    max-width: 60%;
    padding: 8px 15px;
    position: relative;
}
.chat-right .chat-body {
	padding-left: 48px;
	padding-right: 10px;
}
.chat-bubble {
	display: block;
	width: 100%;
	float: left;
	margin-bottom: 10px;
}
.chat-bubble:hover .chat-action-btns {
	display: block;
	float: left;
}
.chat-right .chat-bubble:hover .chat-action-btns {
	float: right;
	display: block;
}
.chat.chat-right .chat-bubble:last-child .chat-content {
	border-bottom-right-radius: 20px;
}
.chat.chat-right .chat-bubble:first-child .chat-content {
	border-top-right-radius: 20px;
}
.chat-content > p {
	margin-bottom: 0;
}
.chat-time {
	color: rgba(0, 0, 0, 0.5);
	display: block;
	font-size: 12px;
}
.chat-right .chat-time {
	text-align: right;
}
.chat-bubble .chat-action-btns {
	display: none;
}
.chat-action-btns {
	float: right;
}
.chat-action-btns ul {
	list-style: none;
	padding: 0;
	margin: 15px 15px 0 15px;
}
.chat-action-btns ul > li {
	display: inline-block;
	margin-left: 5px;
	font-size: 18px;
}
.chat-right .chat-action-btns {
	float: left;
}
.chat-bubble .chat-action-btns a {
	color: #8c8c8c;
}
.chat-line {
	border-bottom: 1px solid #eaeaea;
	height: 12px;
	margin: 7px 0 20px;
	position: relative;
	text-align: center;
}
.chat-date {
	background-color: #f7f7f7;
	color: #727272;
	font-size: 12px;
	padding: 0 11px;
}
.chat-avatar {
	float: right;
}
.chat-avatar .avatar {
	line-height: 30px;
	height: 30px;
	width: 30px;
}
.chat-left .chat-avatar {
	float: left
}
.chat-left .chat-body {
	margin-right: 0;
	margin-left: 30px;
	padding-right: 20px;
}
.chat-left .chat-content {
	background-color: #fff;
	border: 1px solid #eaeaea;
	color: #888;
	float: left;
	position: relative;
	padding: 8px 15px;
	border-radius: 2px 20px 20px 2px;
	max-width: 60%;
}
.avatar {
	background-color: #aaa;
	border-radius: 50%;
	color: #fff;
	display: inline-block;
	font-weight: 500;
	height: 38px;
	line-height: 38px;
	margin: 0 10px 0 0;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	width: 38px;
	position: relative;
	white-space: nowrap;
}
.avatar:hover {
	color: #fff;
}
.avatar.avatar-xs {
	width: 24px;
	height: 24px
}
.avatar > img {
    border-radius: 50%;
    display: block;
    overflow: hidden;
    width: 100%;
}
.chat.chat-left .chat-bubble:first-child .chat-content {
	border-top-left-radius: 20px;
	margin-top: 0;
}
.chat.chat-left .chat-bubble:last-child .chat-content {
	border-bottom-left-radius: 20px;
}
.chat-left .chat-time {
	color: #a9a9a9
}
.attach-list {
	color: #a3a3a3;
	padding: 5px 0 0;
	line-height: 24px;
	margin: 0;
	list-style: none;
}
.attach-list i {
	margin-right: 3px;
	font-size: 16px;
}
.chat-footer {
	background-color: #fff;
	border-top: 1px solid #eaeaea;
	padding: 15px;
}
.message-bar {
	display: table;
	height: 44px;
	position: relative;
	width: 100%;
}
.message-bar .message-inner {
	display: table-row;
	height: 100%;
	padding: 0 8px;
	width: 100%;
}
.message-bar .link {
	color: #777;
	display: table-cell;
	font-size: 20px;
	padding: 0;
	position: relative;
	vertical-align: middle;
	width: 30px;
}
.message-bar .message-area {
	display: table-cell;
	padding-left: 30px;
}
.message-area .input-group .form-control {
    background-color: #fff;
    border: 1px solid #eaeaea;
    box-shadow: none;
    color: #555;
    display: block;
    font-size: 14px;
    height: 44px;
    margin: 0;
    padding: 6px 12px;
    resize: none;
}
.message-area .btn {
	height: 44px;
	width: 50px;
	background: #103675;
	color: #fff;
	padding: 0;
}
.profile-right {
	display: table-cell;
	height: 100%;
	float: none;
	padding: 0;
	position: static;
	vertical-align: top;
}
.display-table {
	display: table;
	table-layout: fixed;
	border-spacing: 0;
	width: 100%;
	height: 100%;
}
.table-row {
	display: table-row;
	height: 100%;
}
.table-body {
	position: relative;
	height: 100%;
	width: 100%;
}
.table-content {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 0;
}
.chat-profile-img {
	padding: 30px;
	position: relative;
	text-align: center;
}
.edit-profile-img {
	height: 100px;
	margin: 0 auto;
	position: relative;
	width: 100px;
	cursor: pointer;
}
.edit-profile-img img {
	border-radius: 50%;
	height: auto;
	margin: 0;
	width: 120px;
}
.change-img {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 50%;
	color: #fff;
	display: none;
	height: 100%;
	left: 0;
	line-height: 120px;
	position: absolute;
	top: 0;
	width: 100%;
}
.edit-profile-img:hover .change-img {
	display: block;
}
.edit-profile-img img {
	height: auto;
	margin: 0;
	width: 120px;
	border-radius: 50%;
}
.user-name {
	color: #333;
}
.edit-btn {
	border-radius: 40px !important;
	height: 36px;
	position: absolute;
	right: 15px;
	top: 15px;
	width: 36px;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
}
.chat-profile-info {
	padding: 15px;
}
.user-det-list {
	list-style: none;
	padding: 0;
}
.user-det-list > li {
	padding: 6px 15px;
}
.user-det-list .text-muted {
	color: #888;
}
.transfer-files .tab-content {
	padding-top: 0;
}
.files-list {
	list-style: none;
	padding-left: 0;
}
.files-list > li {
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	margin: 2px 0 0;
	padding: 10px;
}
.files-cont {
	position: relative;
}
.file-type {
	height: 48px;
	position: absolute;
	width: 48px;
}
.files-icon {
	background-color: #f3f7f9;
	border: 1px solid #e4eaec;
	border-radius: 4px;
	display: inline-block;
	height: 38px;
	line-height: 38px;
	text-align: center;
	width: 38px;
}
.files-icon i {
	color: #76838f;
	font-size: 20px;
}
.files-info {
	padding: 0 30px 0 50px;
}

.file-name a {
	color: #333;
}
.files-action {
	display: none;
	height: 30px;
	list-style: none;
	padding-left: 0;
	position: absolute;
	right: 0;
	text-align: right;
	top: 10px;
	width: 30px;
}
.files-list > li:hover .files-action {
	display: block;
}
.file-date {
	color: #888;
	font-size: 12px;
}
.file-author a {
	color: #00bcd4;
	font-size: 12px;
	text-decoration: underline;
}
.files-action .dropdown-menu {
	left: auto;
	right: 0;
}
.files-action > li > a {
	color: #777;
}
.chat-img-attach {
	border-radius: 4px;
	margin-bottom: 12px;
	margin-left: 12px;
	position: relative;
	float: right;
}
.chat-img-attach img {
	border-radius: 4px;
	display: inline-block;
	height: auto;
	max-width: 100%;
}
.chat-placeholder {
	background: rgba(69, 81, 97, 0.6);
	border-radius: 4px;
	color: #fff;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	left: 0;
	padding: 13px;
	position: absolute;
	top: 0;
	width: 100%;
}
.chat-img-name {
	font-size: 12px;
	font-weight: 700;
	line-height: 16px;
}
.chat-file-desc {
	font-size: 11px;
}
.chat-right .chat-content.img-content {
	background-color: transparent;
	border: 0;
	color: #76838f;
	padding: 0;
	text-align: right;
}
.chat-right .img-content .chat-time {
	color: #a3a3a3;
}
.chat-left .chat-img-attach {
	float: left;
}
.chat-left .chat-img-attach {
	float: left;
	margin-left: 0;
	margin-right: 12px;
}
.input-group .form-control-lg.form-control {
	border-radius: 4px 0 0 4px;
	height: 46px;
}
.chat-user-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.chat-user-list .media {
	border-bottom: 1px solid #eaeaea;
	border-radius: inherit;
	padding: 10px;
	background-color: #fff;
}
.chat-user-list .media:hover {
    background-color: #f7f7f7;
}
.designation {
	color: #9e9e9e;
	font-size: 12px;
}
.online-date {
	color: #9e9e9e;
	font-size: 12px;
}
.drop-zone {
	width: 100%;
	height: 205px;
	border: 2px dashed #adb7be;
	text-align: center;
	padding: 25px 0 0;
	margin-bottom: 20px;
}
.drop-zone .drop-zone-caption {
	font-weight: 600;
}
.upload-list {
	padding: 0;
	list-style: none;
	margin: 0;
}
.upload-list .file-list {
	background-color: #fff;
	border-top: 1px solid #e3e3e3;
	padding: 10px 0;
}
.upload-list .file-list:first-child {
	border-top: none
}
.upload-list .upload-wrap {
	position: relative;
	padding: 0 20px 0 0;
	margin: 0 0 5px
}
.upload-list .file-name,
.upload-list .file-size {
	display: inline-block;
	vertical-align: top;
	white-space: nowrap;
}
.upload-list .file-name {
	padding-right: 15px;
	overflow: hidden;
	max-width: 100%;
	text-overflow: ellipsis
}
.upload-list .file-size {
	color: #888;
}
.upload-list .file-close {
	border: none;
	background: none;
	color: #ccc;
	position: absolute;
	right: 0;
	top: 2px;
}
.upload-list .file-close:hover {
	color: #f62d51
}
.upload-list .upload-process {
	font-size: 10px;
	color: #888;
}
.upload-list .progress {
	margin-bottom: 5px;
}
.upload-list .file-name i {
	color: #888;
	margin: 0 5px 0 0;
	vertical-align: middle;
}
.upload-drop-zone {
    background-color: #fff;
    border: 2px dashed #e3e3e3;
    color: #ccc;
    height: 200px;
    line-height: 200px;
    margin-bottom: 20px;
    text-align: center;
}
.upload-drop-zone.drop {
	color: #222;
	border-color: #222;
}
.upload-text {
	font-size: 24px;
	margin-left: 10px;
}
.files-share-list {
	border: 1px solid #eaeaea;
	border-radius: 4px;
	margin-bottom: 20px;
	padding: 15px;
}

/*-----------------
	20. Focus Label
-----------------------*/

/*-----------------
	43. Chat Sidebar
-----------------------*/

.chat-sidebar {
	background-color: #fff;
	border-left: 1px solid #eaeaea;
	width: 300px;
}
.chat-sidebar .chat-contents {
	background-color: #fff;
}
.chat-sidebar .chat-left .chat-content {
	border: 0;
	padding: 0;
	border-radius: 0 !important;
	background-color: transparent;
	max-width: 100%;
}
.chat-sidebar .chat-left .chat-body {
	margin-left: 50px;
	padding-right: 0;
}
.chat-sidebar .chat-date {
	background-color: #fff;
}
.chat-sidebar .chats {
	padding: 15px 15px 30px;
}
.chat-sidebar .avatar {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
.chat-sidebar .chat.chat-left {
	margin-bottom: 20px;
}
.chat-sidebar .chat.chat-left:last-child {
	margin-bottom: 0;
}
.chat-sidebar .chat-left .chat-time {
	color: #888;
	display: inline-block;
	font-size: 11px;
}
.chat-sidebar .chat-content > p {
	font-size: 13px;
	margin-bottom: 0;
}
.chat-sidebar.opened {
	margin-right: 0;
}

.page-wrapper {
	left: 0;
	margin-left: 270px;
	padding-top: 100px;
	position: relative;
	transition: all 0.2s ease-in-out;
}


.sidebar {
    background-color: #103675;
    border-right: 1px solid transparent;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out 0s;
    width: 260px;
    z-index: 1001;
    margin-top: 100px;
}
.sidebar.opened {
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.sidebar-inner {
	height: 100%;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu {
	padding: 10px 0;
}
.sidebar-menu ul {
	font-size: 15px;
	list-style-type: none;
	margin: 0;
	padding: 0;
	position: relative;
}
.sidebar-menu li a {
	color: #b7c0cd;
	display: block;
	font-size: 15px;
	height: auto;
	padding: 0 20px;
}
.sidebar-menu li a:hover {
	color: #fff;
}
.sidebar-menu li.active a {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.2);
}
.menu-title {
    color: #ebecf1;
    display: flex;
    font-size: 14px;
    opacity: 1;
    padding: 10px 15px;
    white-space: nowrap;
background: rgb(255 255 255 / 10%);}
.menu-title > i {
	float: right;
	line-height: 40px;
}
.sidebar-menu li.menu-title a {
    color: #00bcd4;
    display: inline-block;
    margin-left: auto;
    padding: 0;
}
.sidebar-menu li.menu-title a.btn {
    color: #fff;
    display: block;
    float: none;
    font-size: 15px;
    margin-bottom: 15px;
    padding: 10px 15px;
}
.sidebar-menu ul ul a.active {
	color: #00bcd4;
	text-decoration: underline;
}
.mobile-user-menu {
    color: #fff;
    display: none;
    float: right;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 60px;
    z-index: 10;
}
.mobile-user-menu > a {
    color: #fff;
    padding: 0;
}
.mobile-user-menu a:hover {
	color: #fff;
}
.mobile-user-menu > .dropdown-menu {
	min-width: 130px;
}
.mobile-user-menu > .dropdown-menu > a {
    line-height: 23px;
}
.profile-rightbar {
	display: none !important;
	color: #bbb;
	font-size: 26px;
	margin-left: 15px;
}
.mobile_btn {
	display: none;
	float: left;
}
.sidebar .sidebar-menu > ul > li > a span {
	transition: all 0.2s ease-in-out 0s;
	display: inline-block;
	margin-left: 15px;
	white-space: nowrap;
}
.sidebar .sidebar-menu > ul > li > a span.chat-user {
    margin-left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sidebar .sidebar-menu > ul > li > a span.badge {
	margin-left: auto;
}
.sidebar-menu ul ul a {
	display: block;
	font-size: 14px;
	padding: 9px 10px 9px 50px;
	position: relative;
}
.sidebar-menu ul ul {
	display: none;
}
.sidebar-menu ul ul ul a {
	padding-left: 70px;
}
.sidebar-menu ul ul ul ul a {
	padding-left: 90px;
}
.sidebar-menu > ul > li {
	position: relative;
}
.sidebar-menu .menu-arrow {
	-webkit-transition: -webkit-transform 0.15s;
	-o-transition: -o-transform 0.15s;
	transition: transform .15s;
	position: absolute;
	right: 15px;
	display: inline-block;
	font-family: 'FontAwesome';
	text-rendering: auto;
	line-height: 40px;
	font-size: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	-o-transform: translate(0, 0);
	transform: translate(0, 0);
	line-height: 18px;
	top: 11px;
}
.sidebar-menu .menu-arrow:before {
	content: "\f105";
}
.sidebar-menu li a.subdrop .menu-arrow {
	-ms-transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	transform: rotate(90deg);
}
.noti-dot:before {
	content: '';
	width: 5px;
	height: 5px;
	border: 5px solid #00bcd4;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
	background-color: #00bcd4;
	z-index: 10;
	position: absolute;
	right: 37px;
	top: 15px;
}
.noti-dot:after {
	content: '';
	border: 4px solid #00bcd4;
	background: transparent;
	-webkit-border-radius: 60px;
	-moz-border-radius: 60px;
	border-radius: 60px;
	height: 24px;
	width: 24px;
	-webkit-animation: pulse 3s ease-out;
	-moz-animation: pulse 3s ease-out;
	animation: pulse 3s ease-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	position: absolute;
	top: 8px;
	right: 30px;
	z-index: 1;
	opacity: 0;
}
.sidebar-menu ul ul a .menu-arrow {
	top: 6px;
}
.sidebar-menu a {
	line-height: 1.3;
	transition: unset;
	-moz-transition: unset;
	-o-transition: unset;
	-ms-transition: unset;
	-webkit-transition: unset;
}
.sidebar-menu > ul > li > a {
	padding: 8px 15px;
	align-items: center;
	display: flex;
	justify-content: flex-start;
	position: relative;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li a i {
	display: inline-block;
	font-size: 24px;
	line-height: 24px;
	text-align: left;
	vertical-align: middle;
	width: 20px;
	transition: all 0.2s ease-in-out 0s;
}
.sidebar-menu ul li.menu-title a i {
	font-size: 16px !important;
	margin-right: 0;
	text-align: right;
	width: auto;
}
.sidebar-menu li a > .badge {
    color: #fff;
} 
.main-wrapper-chat {
    position: relative;
    padding-top: 0;
    border-top: 1px solid #e9e9e9 !important;
    margin-top: 0;
    overflow: hidden;
}
.chat-window .learning-wrapper {
    padding: 0;
}

.chat-window .learning-wrapper ul.nav.nav-tabs {
    border-bottom: 2px solid #103675;
}

.chat-window .learning-wrapper li.nav-item {
    width: 50%;
}

.chat-window .learning-wrapper li.nav-item a {
    margin: 0;
    text-align: center;
}

.chat-profile-info .user-det-list > li {
    padding: 0;
}

.learning-wrapper .chat-profile-img {
    padding: 0;
    margin-top: 20px;
}
.edit-profile-img .change-img {
    background-color: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    display: none;
    height: 100%;
    line-height: 100px;
    width: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
}
.custom-modal .chat-user-list a {
    width: 100%;
}

.custom-modal .chat-user-list .media-body {
    line-height: 1.2;
}

.custom-modal ul.chat-user-list {
    margin: 20px 0;
}

.custom-modal .modal-body h5 {margin-top: 25px;}

.custom-modal .modal-header {
    margin-bottom: 15px;
}
.custom-modal p {
    line-height: 1.5;
    margin-bottom: 15px;
    font-size: 16px;
}