@media screen and (min-width: 1367px) {
    .mobile-menu-login, .lardge-dev{display: none;}
}
@media screen and (max-width: 1600px) {

    .main-footer .circle-layer {opacity: 0.1;}
    .header {position: sticky;}

    }
    
    
    @media screen and (max-width: 1400px) {
    .header-search {
        min-width: 240px;
    }
    .featured-topics, section.faq-blocks-section, section.instructor-blocks-section, .testimonial-wrapper{
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .banner-caption.instructor-wrapper {
        padding-left: 0 !important;
    }
    .banner-style-1 .eduvibe-hero-one-video .thumbnail {
        margin-right: -20px;
        margin-left: -20px;
    }
    h1.title.sal-animate {
        font-size: 36px;
    }
    }
    
    @media screen and (max-width: 1366px) {
    .faq-block .inner-box h5 a, section.instructor-blocks-section h3  {
        font-size: 22px;
    }
    .section-title .title {
        font-size: 30px;
    }
    
    .banner-caption.instructor-wrapper p, .testimonial-wrapper p, .main-footer .logo-widget .text {
        font-size: 16px;
    }
    
    .back-btn {
        bottom: 70px;
    }
    
    .main-footer .lower-box {
        margin-bottom: 30px;
    }
    
    .featured-topics li {
        font-size: 13px;
        margin-bottom: 15px;
    }
    
    .featured-topics .section-title {
        margin-bottom: 30px;
    }

    /* Menu */
    span.text-hide-tab {text-indent: -500000px;display: flex;align-items: center;}

.header-cart {
    padding-left: 12px;
    padding-right: 0;
}


.header-login.desktop-login {
    margin-right: 15px;
}

span.text-hide-tab::after {
    content: "Login";
    text-indent: 0;
    display: flex;
    align-items: center;
}

img.desktop-mobile {
    width: 40px;
}
.header-logo ul.main-menu {
    margin-left: 10px;
}
    }

    @media screen and (max-width: 1200px) {
        .section.page-banner-section.bg-color-1.course_detail_banner:after {
            transform: skewX(-11deg);
        }
    }
    @media screen and (min-device-width:992px) and (max-device-width: 1366px) { 
    .lardge-dev{display: block;font-size: 33px;}
 
    
    div#header .container {
        padding: 0 15px;
    }
    
    .header-search.d-none.d-lg-block {
        min-width: 150px;
        width: 190px;
    }
    
    .header-logo ul.main-menu li a.active {
        font-size: 14px;
        width: 156px;
        height: 42px;
        padding-right: 0;
    }
    
    .header-logo ul.main-menu span.menu-icon {
        right: 10px;
    }
    
    .header-logo ul.main-menu li a img {
        width: 12px;
    }
    
    .header-logo ul.main-menu {
        margin-left: 10px;
    } 
    
    .header-cart .cart-btn img {
        width: auto;
    }    
    .header-cart .cart-btn {
        width: 40px;
    }

    }

    @media screen and (min-device-width:992px) and (max-device-width: 1366px) { 
    .lardge-dev{display: block;font-size: 33px;}
 
    
    div#header .container {
        padding: 0 15px;
    }
    
    .header-search.d-none.d-lg-block {
        min-width: 150px;
        width: 190px;
    }
    
    .header-logo ul.main-menu li a.active {
        font-size: 11px;
        width: 182px;
        height: 42px;
        padding-right: 0;
        font-weight: 500;
    }
    
    .header-logo ul.main-menu span.menu-icon {
        right: 10px;
    }
    
    .header-logo ul.main-menu li a img {
        width: 12px;
    }
    
    .header-logo ul.main-menu {
        margin-left: 10px;
    }
    
    
    img.desktop-mobile {
        width: 40px;
    }
    
    .header-cart .cart-btn img {
        width: auto;
    }    
    .header-cart .cart-btn {
        width: 35px;
    }
    }
    @media screen and (max-width: 1024px) {
        
        
        .course-new-list-inner p {
            font-size: 11px;
        }
        .counter-section-content p {
            font-size: 14px;
            line-height: 1.4;
        }
        
        .counter-section-counts-outer h6 {
            font-size: 21px;
        }
        
        .counter-section-counts-outer p {
            font-size: 13px;
            line-height: 1.4;
        }

        .new-slider-inner img {
            max-height: 248px;
            min-height: 248px;
            max-width: 320px;
        }
        .new-slider-section .section-title {
            margin-bottom: 0;
        }
       
        .new-contact {
            margin-top: 67px;
        }

        .banner-style-1 .banner-right-content .edu-card {
            position: relative;
            z-index: 1;
        }
        .banner-style-1 .eduvibe-hero-one-video .thumbnail {
            margin: 46px -20px 0 -20px;
        }
        .new-footer-link ul li a {
            font-size: 13px;
        }
        .new-footer-logo-one p {
            font-size: 13px;
        }
        .new-footer-logo-two p {
            font-size: 13px;
        }
        .faq-block .inner-box h5 a {
            font-size: 18px;
        }
        .add-btn .add-cart-btn {
            line-height: 37px;
        }
        .become-inner {
            width: 130px;
        }
        .section.page-banner-section.bg-color-1:after {
            content: "";
            background: rgb(242,242,242);
            background: linear-gradient(90deg, rgba(242,242,242,0.7) 30%, rgba(0,212,255,0) 100%);
            position: absolute;
            left: 0;
            height: 100%;
            top: 0;
            z-index: -1;
            width: 100%;
        }

        .section.page-banner-section.bg-color-1.course_detail_banner:after {
            transform: skewX(0);
            background-color: rgba(0, 0, 0, 0.5);
            width: 100%;
        }
        .section.page-banner-section.bg-color-1.course_detail_banner {
            background-size: 100% 100%;
        }
    }
    @media screen and (max-width: 1199px) {    
    .header-search input:focus {
        width: 100%;
    }
    
    .header-search button {
        appearance: none;
    }
    
    .header-search input:focus + button {
        appearance: auto;
    }
    .featured-topics .row .col-md-3 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
        margin-bottom: 30px;
    }
    section.instructor-blocks-section img {
        max-width: 80%;
        margin: 0 auto;
        display: block;
    }
    section.instructor-blocks-section {
        padding-top: 50px;
    }
    .sidebar-description form select {
        font-size: 14px;
    }
    .sticky-bar-comm {
        width: 100%;
    }
    .sticky-bar-sticky {
        top: 66px;
    }
    .wrapper-info-about img {
        max-width: 100%;
    }
    .faq-block .inner-box {
        height: 100%;
    }
    .team-member.text-center {
        margin-bottom: 20px;
    }
    .header-login {
        margin-right: 15px;
    }
    .payment-option {
        flex-wrap: wrap;
        margin-bottom: 40px;
    }
    .comment-form-consent {
        width: 50%;
        flex: 0 0 50%;
        margin-bottom: -1px;
    }
    }
    
    @media screen and (max-width: 991px) {
    
    .header-cart .cart-btn {
        width: 30px;
    }
    
    /* .slider-wrapper {
        padding-top: 50px;
    } */
    
    .header-logo ul.main-menu li {
        padding-top: 0;
        padding-bottom: 0;
    }
    
    .header-menu-hover + .hover-div-menu {
        top: 80px;
    }
    .header-toggle button {
        background: #fff !important;
    }
    .header-menu .sub-menu ul.sub-menu .row .col-md-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    
    .header-menu .sub-menu ul.sub-menu {
        max-width: 100%;
        padding: 20px;
    }
    
    .header-menu .sub-menu ul.sub-menu li h4 + p {
        white-space: normal;
        margin-top: 6px;
        line-height: 1.4;
    }
    
    .header-menu .sub-menu ul.sub-menu h5 {
        font-size: 14px;
    }
    .header-cart .dropdown-cart {
        top: 57px !important;
    }
    .banner-style-1 .eduvibe-hero-one-video .thumbnail {
        margin: 0;
    }
    
    .edu-card.card-type-6.radius-small.sal-animate {
        margin-right: 30px;
        width: 310px;
        margin-left: auto;
    }
    .banner-right-content {
        margin-top: 50px;
    }
     
    
    h1.title.sal-animate {
        padding: 0;
    }
    .work-shop.sal-animate.hero-shap-5 {
        min-width: 353px;
        max-width: 343px;
        text-align: center;
        margin-top: -20px;
    }
    .section.page-banner-section.bg-color-1 {
        margin-top: 80px;
    }
    .mobile-menu-login {
        display: block;
    }
    .subscribe-form {
        width: 100%;
        max-width: 500px;
    }
    .section.page-banner-section.bg-color-1:after {
        background: rgba(255,255,255,0.8);
    }
 
    .sticky-bar-sticky {
        top: 52px;
    }
    .sidebar-details-wrap{
        margin-top: -100px;
    }

    /* details page  */
    .sidebar-description form .form-group {
        width: calc(50% - 15px);
        display: inline-block;
        margin-right: 15px;
    }
    
    .sidebar-details-video-description .sidebar-description .btn {width: calc(249px - 30px) !important;}
    
    .courses-btn {
        justify-content: flex-start;
    }
    
    .sidebar-description form .form-group:last-child {
        margin-right: 0;
    }


    /* explore menu */
    ul.main-menu.result.result_hover ul.sub-menu {
        position: absolute;
        left: 0;
        transform: translateY(0);
    }
    
    .header-menu .sub-menu {
        left: -100%;
        transform: translate(-100%);
        height: 100% !important;
        min-height: 100vh;
        overflow: scroll;
    }
    
    ul.main-menu.result.result_hover ul.sub-menu ul.sub-menu {
        position: relative;
        height: 0 !important;
        max-height: none;
        min-height: unset;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        padding-bottom: 0;
        padding-top: 0;
    }
    body ul.main-menu.result.result_hover ul.sub-menu .close-bar-hover {
        height: auto !important;
        max-height: 100% !important;
        opacity: 1 !important;
        visibility: visible;
        padding-bottom: 10px;
        padding-top: 10px;
    }
        .header-logo ul.main-menu li ul.sub-menu li a {padding: 6px 20px;}
        .header-menu .sub-menu {box-shadow: none;}
        .header-logo ul.main-menu li ul.sub-menu h6 {padding: 0 20px;}
        .header-logo ul.main-menu li ul.sub-menu li a span.menu-icon {right: 10px;color: #fff !important;}
        a.view-all-menu {background: #103675;width: auto;max-width: 130px;color: #fff !important;}
        .header-logo ul.main-menu li ul.sub-menu li a span.menu-icon::after {color: #fff !important;}

        .app-download-offer a {
            /* display: flex; */
            font-size: 16px;
            color: #000;
            font-weight: 600;
            align-items: center;
        }
        
        .app-download-offer {
            padding: 20px 0 20px 0 ;
            border-bottom: 1px solid #dbdbdb;
            border-top: 1px solid #dbdbdb;
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        
        .app-download-offer a img {
            /* width: 60px; */
            /* border: 2px solid #f2f2f2; */
            /* background: #fff; */
            padding: 8px 7px;
            border-radius: 4px;
            /* margin-right: 20px; */
        }
        
        .app-download-offer a .offer-wrapper {
            line-height: 1.2;
            font-weight: 600;
            margin-top: 0;
            font-size: 16px;
        }
        span.offer-wrapper small {
            display: flex;
            align-items: center;
            color: #6d6d6d;
            margin-top: 5px;
            font-size: 14px;
            text-transform: uppercase;
        }
        
        span.offer-wrapper small span {
            color: #ff9800;
            margin-right: 6px;
        }
        /* .header-search {
            min-width: 100%;     margin: 0;
        } */
        .checkout-page-style {
            width: 100%;
        }
        
        .checkout-form {
            flex-direction: column;
        }
        .sidebar-description form .form-group {
            width: 100%;
            margin-right: 0;
        }
        
        .courses-btn {
            flex-wrap: wrap;
        }
        
        .sidebar-details-video-description .sidebar-description .btn {
            width: 100% !important;
            margin-top: 0;
            margin-bottom: 15px;
            margin-left: 0;
        }
        
        .course-details-wrapper {
            margin-top: 30px;
        }
        .course-overview .col-md-5 {
            margin-top: 25px;
        }
        
        .course-overview .col-md-5 img {
            width: 100%;
        }
        .cart-summary-button-group .btn {
            width: 50% !important;
        }
    }
    
    @media screen and (max-width:991px) {

        .header-search .web-search {
            display: none;
            background-color: red
        }

        .header-menu .sub-menu {
            left: -0%;
            transform: translate(-0%);
            height: 100% !important;
            min-height: 100vh;
            overflow: scroll;
        }
        a.active.main-linking {
            font-size: 0!important;
        }

        .header-logo ul.main-menu li a.active {
            width: auto;
            padding: 0 11px;
            height: 37px;
        }
        
        .header-logo ul.main-menu span.menu-icon {
            position: absolute;
            right: 20px;
        }
        .banner-caption.instructor-wrapper a.btn {
            width: 100%;
            padding: 20px;
            margin-bottom: 10px
        }
        .courses-btn .btn:last-child {
            margin: 0;
        }
        .merchandise-section .section-title {
            text-align: center;
        }
        .new-contact {
            margin-top: 55px;
        }
        .about-banner .courses-btn a {
            flex: 0 0 100%;
            margin-bottom: 10px;
        }
        .widget-search-mobile {
            display: block;
        }
        .widget-search-desktop {
            display: none;
        }
        .sidebar-widget.sidebar-widget-about .textwidget img {
            width: 100%;
            height: 100%;
        }
        .merchandise-all .row {
            flex-direction: column-reverse;
        }
        
        .merchandise-all {
            padding: 0 0 50px 0!important;
        }
        .edu-card.card-type-6.radius-small.sal-animate, .work-shop.sal-animate.hero-shap-5 {
            width: auto;
            min-width: auto;
            max-width: initial;
            margin-left: 0;
            margin-right: 0;
            border-radius: 0;
        }
        .work-shop.sal-animate.hero-shap-5 {
            margin-top: 0;
            margin-left: 50px;
        }
        .edu-card-wrapper {
            max-width: 100%;
            flex: 0 0 100%;
            display: flex;
        }
        .video-thumbnail.eduvibe-hero-one-video {
            display: none;
        }
        .work-shop.sal-animate.hero-shap-5 .inner {
            height: 100%;
            position: relative;
        }
        
        
        .work-shop.sal-animate.hero-shap-5 .content {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        .faq-block .inner-box h5 a {
            font-size: 20px;
        }
        .sidebar-widget .widget-search {
            max-width: 50%;
        }
        .download-btn ul li a {
            width: auto;
            display: inline-flex;
            margin-top: 14px;
        }
        .wrapper-brochures {
            display: block;
        }
        .header-menu .sub-menu ul.sub-menu {
            top: auto!important;
            left: auto!important;
        }
        
        .header-menu .sub-menu li:hover>.sub-menu {
            top: auto!important;
        }
        
        .header-logo ul.main-menu li ul.sub-menu li {
            position: relative;
        }

        .header-menu .main-menu li .sub-menu-main li:hover .sub-menu.close-bar-hover {
            opacity: 1;
            visibility: visible;
            
        }
        .header-menu .main-menu li .sub-menu-main li:hover .sub-menu {
            opacity: 0;
            visibility: hidden;
        }
        .job-detail-apply {
            text-align: left;
            margin-top: 10px;
        }
    }


    @media screen and (max-width: 860px) {

    .featured-topics .row .col-md-3 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    
    
    .featured-topics h3 {
        font-size: 18px;
    }
    .main-footer .lower-box .singup {
        position: static;
        margin-top: 20px;
    } 

body header.card__header {
    width: 100%;
    margin-bottom: 20px;
}

body ul.card__list.grid {
    width: 100%;
}

ul.card__list.grid li {
    width: 100%;
    margin-bottom: 19px;
}

.currency-blocks {
    flex-wrap: wrap;
}

body .currency-blocks .example {
    width: 100%;
    padding: 14px 0;
    justify-content: center;
}

.currency-label {
    width: 100%;
    margin-bottom: 15px;
}

body .currency-changer p {
    max-width: 100%;
    margin: 0;
}

.form-switch {
    padding: 0;
}
body .pricingtable {
    max-width: 46%;
    min-width: 46%;
}

body .pricingtablecontainer {
    overflow: scroll;
    justify-content: flex-start;
}

.banner-caption.instructor-wrapper .courses-btn {
    flex-direction: column;
    justify-content: center;
}

.banner-caption.instructor-wrapper a.btn {
    width: 100%;
    margin: 0 0 15px 0;
}
.sticky-bar-comm.sticky-bar-sticky {
    top: 54px;
}
.share-certficate {
    justify-content: flex-start;
    margin-top: 30px;
}

.frequently-asked-questions {
    padding: 80px 0;
}
.course-details-wrapper .course-learn-list {
    padding-left: 15px;
    padding-right: 15px;
}

.work-shop.sal-animate.hero-shap-5 .content {
    position: relative;
}

}

    @media screen and (max-width: 768px) {
        /* .course-new-list-inner p {
            font-size: 8px;
        } */
        
        .course-new-list-outer {
            padding: 10px 0;
        }
        .app-section-list ul li {
            font-size: 13px;
        }
        
        .app-section-content h5 {
            font-size: 37px;
        }
        .new-footer-logo-one p {
            font-size: 13px;
        }
        
        .new-footer-logo-one a img {
            /* margin: 5px 0; */
            margin: 10px 4px;
            width: 99px;
        }
        
        .new-footer-link ul li a {
            font-size: 12px;
        }
        
        .new-footer-link h6 {
            font-size: 13px;
        }
        
        .new-footer-logo-two p {
            font-size: 13px;
        }
        .course-new-list-inner {
            border: 0;
        }
        .new-contact {
            margin-top: 54px;
        }
        .merchandise-details-content {
            margin-top: 50px;
        }
        
        .merch-sidebar {
            margin-top: 50px;
        }
        .new-footer-link ul li {
            line-height: 1;
        }

        .new-footer-link ul li a {
            font-size: 11px;
            padding-bottom: 3px;
        }
        .new-footer-logo-one p {
            font-size: 10px;
        }
        .new-footer-logo-two p {
            font-size: 10px;
        }
    }

    /* END */
    @media screen and (max-width: 767px) {

    .certificate.text-center {
        text-align: left !important;
    }
    
    .certificate.text-center img {
        margin-top: 12px;
    }
    .video-thumbnail.eduvibe-hero-one-video {
        display: none;
    }
    
    .edu-card.card-type-6.radius-small.sal-animate, .work-shop.sal-animate.hero-shap-5 {
        width: auto;
        min-width: auto;
        max-width: initial;
        margin-left: 0;
        margin-right: 0;
    }
    .edu-card-wrapper {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .work-shop.sal-animate.hero-shap-5 {
        margin-top: 0;
        width: calc(50% - 30px);
        margin-left: 30px;
        align-items: center;
        display: flex;
        justify-content: center;
        outline: 3px solid #ececec;
        outline-offset: -15px;
    }
    .edu-card-wrapper + .col-md-6 {
        display: none;
    }
    .card-bottom a.edu-btn {
        text-align: center;
        width: 100%;
        max-width: 150px;
    }
    
    .edu-card.card-type-6 .inner {
        padding: 15px;
    }
    .banner-caption.instructor-wrapper {
        margin-top: 50px;
        text-align: center !important;
    }
    
    .banner-caption.instructor-wrapper .courses-btn {
        justify-content: center;
        margin-top: 40px !important;
    }
    
    .banner-caption.instructor-wrapper a.btn {
        display: inline-flex;
    }
    section.instructor-blocks-section.gray-bg .col-md-6:nth-child(1) {
        order: 2;
    }
    
    section.instructor-blocks-section.gray-bg .col-md-6:nth-child(2) {
        order: 1;
    }
    
    .slider-wrapper .content {text-align: left;}
    .offcanvas-logo a img {width: 190px;}

    .featured-topics .container h2 {
        text-align: center;
    }
    
    .featured-topics .section-title {
        text-align: center;
    }
    
    .featured-topics .section-title .btn {
        margin-top: 21px;
    }
    .main-footer .footer-bottom .nav-column ul {
        text-align: left;
    }
    .work-shop.sal-animate.hero-shap-5 {
        margin-left: 15px;
    }
   .banner-caption.instructor-wrapper .courses-btn {
    flex-direction: row; 
}

.course-instructor {
    margin-bottom: 10px !important;
}

div#instructors {
    padding-bottom: 70px;
}
.course-review h3.title {
    text-align: center;
}
body article.card__content {
    padding: 15px;
}


.section.page-banner-section.bg-color-1 {
    text-align: center;
    padding-top: 40px;
}

body .course-details-banner-content {
    margin: 0 auto;
    max-width: 100%;
    text-align: left;
}

body .courses-btn .btn:last-child { 
    margin: 0;
}

/* box */
.plan-info {
    white-space: nowrap;
}

div#price-table .plan-collapse-inner {
    overflow: scroll !important;
}
.compare-pricing {
    order: 3;
}

.plan-benifits {
    order: 4;
}

.slideToggle {
    order: 1;
}

.pricingtablecontainer {
    order: 2;
}

.full-container-inner {
    display: flex;
    flex-wrap: wrap;
}

.res-s-bttm {
    width: calc(50% - 20px);
    margin: 10px auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 15px 15px;
    border: 1px solid #ccc;
}

.text-box-new {
    width: 100%;
}


.left-space {
    padding: 0;
    margin-top: 50px;
}

.wrapper-info-about img { 
    display: block;
}
/* .page-title-section .content-box {
    margin-bottom: 30px;
} */

body .pricingtable {max-width: 280px;}

.add-ons .inner-box { 
    padding: 30px 30px; 
}
.government-space {
    padding: 30px 0 0 0;
}

.add-ons .inner-box a.btn {
    margin-top: 0;
}

section#our-mission .text {
    padding: 0 0 10px 0;
}
.add-ons .inner-box img {
    width: 100%;
}

.sticky-bar-comm ul li a {
    white-space: pre;
}

.sticky-bar-comm ul {
    overflow-x: scroll;
    justify-content: flex-start;
}
.contact-page-section .info-column ul li {
    background: #f1f1f1;
    padding: 20px 20px;
}

.feature-intro img {
    width: 100%;
}

.feature-intro .col-md-6 .col-md-6 {
    width: 50%;
}

.feature-intro .col-md-6 .col-md-12 {
    margin-bottom: 30px;
}

.recruiters-profile img {
    width: auto;
}

.wrapper-brochures {
    flex-wrap: wrap;
}

.wrapper-brochures .download-btn {
    margin-top: 20px;
}
.header-logo ul.main-menu {
    margin-left: 10px;
}
img.desktop-logo {
    display: none;
}
img.desktop-mobile {
    display: block;
}
.header-logo ul.main-menu li a.active {
    width: auto;
    padding: 0 11px;
    height: 37px;
}

.merch-view-btn {
    margin-top: 20px;
}
.app-section-image {
    margin-top: 30px;
}

.new-footer-logo-two.new-footer-logo-two-mobile {
    display: block;
}

.new-footer-logo-two.new-footer-logo-two-desktop {
    display: none;
}
.new-footer-copyright {
    text-align: center;
    margin-bottom: 15px;
}

.certificate {
    margin-bottom: 15px;
}


.courses-tab-new .nav-item {
    display: block;
    width: 100%;
    text-align: center;
}
.header-cart .cart-btn::before {
    display: none;
}
.header-cart {
    margin-right: 10px;
}
.sticky-bar-comm ul li a::after {
    display: none;
}
.new-contact {
    margin-top: 0;
}

.notice-board {
    padding-top: 100px!important;
}
.merchandise-details {
    margin-top: 70px;
}

.edu-card .card-bottom {
    text-align: center;
}
.become-inner {
    width: auto;
}
.section.page-banner-section.bg-color-1 {
    background-position: right;
}
.job-detail-content .job-detail-list {
    display: block;
}

.job-detail-content .job-detail-list li {
    padding: 0;
}
.course-details-banner-content .title {
    font-size: 20px;
}
.header-login .dropdown-menu {
    min-width: max-content;
    margin-right: -50px;
}

.course-details-banner-content.shopping-cart {
    position: relative;
    top: -50px;
}
.section.page-banner-section.bg-color-1.cart-banner {
    margin-top: 100px;
}

}
@media screen and (max-width: 640px) { 
    
    .section-title .title {font-size: 19px;}

div#header .container {
    padding: 0 15px;
}
 
.work-shop.sal-animate.hero-shap-5 {
    width: calc(65% - 3px);
}
img.desktop-mobile {
    display: block !important;
    width: 45px;
}
.about-banner .courses-btn {
    max-width: 70%;
}

.about-banner .courses-btn a {
    flex: 0 0 100%;
    margin-bottom: 15px;
}
.modal-content.newspaper li {
    width: calc(50% - 20px);
    background: #f1f1f1;
}

.modal-content.newspaper li {
    color: #000;
}

div#language {
    margin: 0 auto;
    padding: 0 30px !important;
}
.team-member.text-center {
    background: #f2f2f2;
    margin-bottom: 30px;
    padding-bottom: 30px;
}

.faq-blocks-section .inner-container {
    margin-top: -60px;
}
.sticky-bar-comm.sticky-bar-sticky {
    top: 48px;
}
.about-banner .courses-btn {
    max-width: 100%;
}

.about-banner .courses-btn a.btn {
    max-width: 100%;
}
.recruiters-profile a {
    margin-top: 30px;
    width: 100%;
}

.recruiters-profile {
    text-align: center;
}
.download-btn {
    width: 100%;
    text-align: center;
}

.download-btn ul li a {
    justify-content: center;
}

section.about-us-trial h2 {
    line-height: 1.2;
}
}


@media screen and (max-width: 540px) {
.featured-topics .row .col-md-3 {
    max-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 0;
} 

.header-cart .cart-btn {
    width: 30px;
}

.header-cart {
    margin-right: 11px;
}

.header-logo ul.main-menu span.menu-icon {
    right: 35px;
}

.header-logo ul.main-menu li a img {
    width: 10px;
}

img.desktop-mobile {
    width: 48px;
    margin-right: 0;
}
.res-s-bttm {
    width: calc(100% - 20px); 
}

.summary-outer {
    display: block;
}

.summary-right {
    text-align: left;
    margin-top: 20px;
}

}

@media screen and (max-width: 500px) {

.web-search {
    display: none;
} 

.header-logo ul.main-menu {
    margin-left: 0;
}
 
ul.banner-icon-list-items li {
    display: flex;
    align-items: normal;
    line-height: 1.2;
    margin-bottom: 15px;
}

ul.banner-icon-list-items li i {
    margin-top: 0;
}

ul.banner-icon-list-items {
    margin-top: 35px;
}

.work-shop.sal-animate.hero-shap-5 {
    width: 100%;
    margin:  0 !important;
    margin-top: 20px !important;
    padding: 40px 20px;
}

.edu-card-wrapper {
    flex-wrap: wrap;
}
.slider-wrapper {
    margin-top: 65px;
}
h1.title.sal-animate {
    font-size: 28px;
}

p {
    font-size: 14px;
}

ul.banner-icon-list-items li {
    font-size: 14px;
}
.banner-caption.instructor-wrapper .courses-btn {
    flex-direction: column;
    justify-content: center;
}

.banner-caption.instructor-wrapper a.btn {
    width: 100%;
    margin: 0 0 15px 0;
}

/* smalll mobile */
img.desktop-mobile {
    width: 38px;
}

.header-logo ul.main-menu li a.active {
    width: auto;
    padding: 0 11px;
    height: 37px;
}

.header-logo ul.main-menu span.menu-icon {
    right: 10px;
}

div#header {
    padding: 10px 0;
}

.section.page-banner-section.bg-color-1 {
    margin-top: 66px;
}
.header-menu-hover + .hover-div-menu {
    top: 65px;
}
.sticky-bar-comm.sticky-bar-sticky {
    top: 40px;
}
.new-contact {
    margin-top: -11px;
}
.contact-page-section .form-column .circle-layer {
    display: none;
}
}
@media (min-width: 576px){
    /* .modal-dialog {
        max-width: 425px;
    } */
}
@media screen and (max-width: 576px) {
    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }
}
@media screen and (max-width: 480px) {
span.text-hide-tab::after {
    display: none;
}

.lardge-dev {
    margin-right: 0;
}
}