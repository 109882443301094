
.mainCard{
    /* min-height: 80vh; */
    width: 100%;
    background: #405189;
    padding: 1px;
    position: relative;
    display: flex;
  }
  .card1 {
    margin-top: 9%;
    margin-left: 8%;
    width: 80%;
  }
  .card1 h1 {
    font-size: 32px;
    margin-bottom: 1.5rem;
    color: #fff;
  
  }
  
  .card1 p {
    font-size: 15px;
    color: #fff;
    letter-spacing: 5x;
    margin-bottom: 7%;
  
  }
  
  .mainCard>.img {
    height: 380px;
    width: 300px;
  }
  
  #phone1 {
    margin-left: -1%;
  
  }
  
  #phone2 {
    margin-top: 8.3%;
     margin-right: 5%; 
  }
  
  #play {
    height: 45px;
  
  }

  .qrContainer{
    width: 150px;
    height: 150px;
  }
  
  .appFlex{
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .App {
    margin-top: 8px;
  
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .play{
    margin-left: 8%;
  }
  .play>h5{
    color: #fff;
  }
  
  @media only screen and (max-width: 992px){
    .mainCard{
      display: block;
    }
    .card1 {
      margin-top: 5%;
      margin-left: 4%;
      width: 80%;
  
    }
    .card1 h1{
      font-size: 30px;
    }
    #phone2 {
      margin-top: 10%;
    
    }
    #phone1 {
      margin-left: 10%;
  
      margin-top: 10%;
    }
    .play{
      margin-left: 4%;
  
    }
    
  
  }
  
  @media only screen and (max-width: 768px){
    .mainCard{
      display: block;
    }
  
    .component{
      margin-left: 6%;
      margin-top:  6%;
    }
    #phone2 {
      margin-top: 0%;
      margin-left: 8%;
    }
    #phone1 {
      margin-left: 8%;
    }

    .qrContainer{
      width: 80px;
      height: 80px;
    }
    .appFlex{
      display: flex;
      gap: 10px;
    }
  
  }
  
  @media only screen and (max-width: 576px){
  
    .mainCard{
      display: block;
    }
    .card1 h1{
      font-size: 25px;
    }
    .img{
      height: 300px;
      width: 200px;
    }
    #phone1{
      margin-left: 15%;
      display: none;
    }
    #phone2{
      margin-top: 5%;
      /* margin-left: 14%; */
    }
    #play{
      height: 30px;
    }

    .app {
      display: flex;
      flex-direction: row;
    }
  }