.contact-become-inner {
    background: #405189;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    height: 145px;
    width: 220px;
    margin: 0 auto 45px auto;
}

.contactFinal-outer {
    width: 80%;
    margin: 0 auto;
}

.contactModal-become-inner {
    background: #405189;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    height: 70px;
    width: 210px;
    margin: 0 auto 25px auto;
}

.contactFinal-become-inner {
    background: #405189;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    height: 170px;
    width: 310px;
    margin: 0 auto 25px auto;
}


@media screen and (max-width: 1024px) {
    .contact-become-inner {
        width: 210px;
        margin: 0 auto 20px auto;
    }
    .contactModal-become-inner {
        width: 210px;
        margin: 0 auto 15px auto;
    }
    .contactFinal-become-inner {
        width: 210px;
        margin: 0 auto 15px auto;
    }
}

@media screen and (max-width: 767px) {
    .contact-become-inner {

        width: auto;
    }
    .contactModal-become-inner {
        width: 90%;
    }
    .contactFinal-become-inner {

        width: 90%;
    }

    .contactFinal-outer {
        width: 100%;
    }
}

