.pricingtablecontainer {
  display: flex;
  flex-wrap: nowrap;
  
  
  padding: 70px 0 0 0;
justify-content: center;}

.pricingtable {
  padding: 0 0;
  max-width: 25%;
  min-width: 25%;
  flex: 1 0 100%;
}
.pricingtable .js-yearlypricing {
  display: none;
}
.pricingtable ul {
  border: 1px solid #f1f1f1;
  border-radius: 0;
  position: relative;
  padding: 20px 24px;
  background: #fff;
  margin-left: -1px;
  height: 100%;
  padding-bottom: 100px;
  }
.pricingtable li {
  display: flex;
  
  
  align-items: center;
  justify-content: center;
flex-wrap: wrap;position: relative;/* font-weight: bold; */font-family: 'Inter', sans-serif;letter-spacing: 0;}
.pricingtable li.disable {
  opacity: 0.5;
  text-decoration: line-through;
}
.pricingtable__head {
  border: none !important;
  text-transform: capitalize;
  font-weight: bold;
  color: #103675;
}
.pricingtable__highlight {
  
  border-radius: 38px !important;
  color: #333;
  padding: 5px 15px;
  
font-weight: 600 !important;font-size: 14px;border: solid 1px #ccced3;max-width: 150px;margin: 0 auto;}
.pricingtable__popular {
  background: #2196f3;
  border: none !important;
  border-radius: 5px 5px 0 0;
  color: white;
  padding: 5px !important;
  position: absolute !important;
  top: -40px;
  left: 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: normal !important;
  font-size: 16px;
  padding: 8px 15px !important;
  }
.pricingtable__btn {
  border: none !important;
} 
.pricingtable .platinum .pricingtable__highlight {
  background: #a182d2;
}

.slideToggle {
  display: flex;
  justify-content: center;
  margin: 50px 0 10px 0;
}
.slideToggle i {
  margin: 0 15px;
}

.form-switch {
  align-items: center;
  display: flex;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  justify-content: space-between;
  
margin: 0;}

.form-switch i {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 30px;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  transition: all 0.3s linear;
background: #fff;}

.form-switch i::after {
  content: "";
  position: absolute;
  left: 0;
  width: 40px;
  height: 22px;
  background-color: #8bc34a;
  border-radius: 15px;
  transform: translate3d(4px, 3px, 0);
  transition: all 0.2s ease-in-out;
}

.form-switch input {
  display: none;
}

.form-switch input:checked + i::after {
  transform: translate3d(54px, 3px, 0);
  background: #2196f3;
  }
.pricingtable li.list{
  text-align: left;
  justify-content: flex-start;
  font-size: 16px;
  padding-left: 37px;
  font-weight: normal;
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 1.3;
  }

.pricingtable li.list:after{
    position: absolute;
    content: "\f00c";
    left: 10px;
    top: 13px;
    color: #4659e2;
    font-size: 14px;
    line-height: 1em;
    font-family: 'FontAwesome';
    transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
}
li.pricingtable__highlight {margin-bottom: 30px;margin-top: 10px;border-radius: 10px;} 
.pricingtable li p {text-align: center;margin-bottom: 15px;margin-top: 10px;font-size: 12px;line-height: 1.4;font-weight: 400;color: #000;}
li.pricingtable__btn button {margin-top: 0;margin-bottom: 0;width: 100%;max-width: 190px;font-size: 14px;border-radius: 0;height: 40px;background-color: #103675;border-color: #103675;line-height: 40px;font-weight: normal;}

.text-success {color: #2196f3 !important;}  

.compare-pricing {text-align: center;margin-top: 40px;}

.compare-pricing a {font-size: 20px;background: #ffffff;padding: 10px 30px;color: #103675;display: flex;align-items: center;max-width: 290px;margin: 0 auto;transition: all 0.5s;border: 2px solid #103675;justify-content: space-between;}
.compare-pricing a svg {margin-left: 10px;}
.compare-pricing a:hover {background: #2196f3;color: #fff;text-decoration: none;border-color: #2196f3;}
.plan-benifits ul li.gray-bg {
    background: #f8f9fb;
}
.plan-benifits ul li {
    list-style: none;
    display: inline-block;
    width: calc(100% / 5);
    border: solid 1px #f1f2f5;
    margin-left: -1px;
    margin-top: -1px;
    background: #fff;
    padding: 16px 30px;
}
.plan-benifits ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0;
    margin-bottom: -1px;
}
.plan-benifits ul li.status span {
    display: block;
    text-align: center;
    padding: 12px 0 0 0;
}

.plan-benifits ul li strong {display: block;font-size: 16px;margin-bottom: 0;}

.plan-benifits ul li small {font-size: 14px;display: block;}

.plan-benifits ul li small svg, .plan-benifits ul li strong svg {margin-left: 5px;color: #009688;font-size: 16px;}
div#price-table {margin: 0 auto;}
li.gray-bg.status {text-align: center;}
.plan-benifits ul li.status span img {width: 24px;}
li.plan-type-wrapper {text-align: center;font-size: 16px;}
.currency-blocks {max-width: 100%;width: 100%;background: #103675;display: flex;justify-content: space-between;align-items: stretch;padding: 0;}

label.form-switch {color: #4caf50;}

.currency-blocks .example {background: #ffffff17;padding: 0 40px;display: flex;}

span.beforeinput.text-success {color: #8bc34a !important;}

span.afterinput {color: rgb(255 255 255 / 35%);}

span.beforeinput {color: rgb(255 255 255 / 35%);}

.currency-changer {padding: 15px 30px;display: flex;flex-wrap: wrap;align-items: center;}

.currency-changer p {font-size: 13px;max-width: 40%;line-height: 1.2;margin-left: 20px;color: #9c9c9c;}

.currency-label span {display: block;font-size: 14px;margin-left: 0;line-height: 1;}

.currency-label {background: #fff;padding: 6px 10px;border: 1px solid rgb(51 51 51 / 23%);border-radius: 0;}

.currency-label select {border: 0;padding: 0;height: 35px;font-size: 16px;}
section.pricing-wrapper-box {
    padding: 80px 0;
}
.full-container {
    max-width: 1500px;
    margin: 0 auto;
}
li.pricingtable__btn {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    padding: 0 0 30px 0;
}
section.pricing-wrapper-box h5.heading-sm-lead {
    font-weight: normal;
}
article.card__content {
    display: flex;
    background: #ffffff;
    flex-wrap: wrap;
    padding: 30px 50px;
    border: 1px solid #f2f2f2;
    margin-top: 30px;
}

ul.card__list.grid {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
}

li.card__list-item {
    width: 50%;
    display: flex;
}

li.card__list-item i {
    margin-top: 6px;
    margin-right: 10px;
    color: #3f51b5;
}

header.card__header {
    width: 30%;
}

a.card__button {
    width: 100%;
}

h1.card__header-title {
    font-size: 26px;
}
.card__container.full-price .container {
    max-width: 100%;
    padding: 0;
}
span.no-class {
    opacity: 0.5;
}
.compare-pricing a i {
    font-size: 27px;
}
a.change-text i {
    transform: rotateX(180deg);
}

a.change-text.collapsed i {
    transform: rotateX(0);
}
 
 /*plan*/
 
/*plans-wrapper-css */
.pricing-plans {
  font-family: "Roboto", sans-serif;
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  }
  .pricing-plans .plan {
  width: 90%;
  margin: 0 auto;
  min-width: 0;
  -webkit-box-shadow: 0 3px 0px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 0px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease-in-out;
  padding: 2.25rem 0;
  position: relative;
  will-change: transform;
  text-align: center;
  }
  .pricing-plans .plan:before {
  position: relative;
  content: "";
  border: 0;
  width: 100%;
  display: block;
  padding: 10px 15px;
  height: 42px;
  border-right: 0 !important;
  }
  
  @media only screen and (min-width: 1200px) {
  .pricing-plans .plan {
    width: 19%;
  }
  }
  .pricing-plans .plan-number-wrapper {
    width: 5%;
  }
  
  .pricing-plans .plan:nth-child(3) {
    width: 12.1%;
  }
  
  .pricing-plans .plan.options {
  font-size: 0.75rem;
  }
  .pricing-plans .plan.popular:before {
  background-color: #b30f22;
  content: "MOST POPULAR";
  border-color: #ddd;
  color: #fff;
  font-weight: 600;
  }
  .pricing-plans .plan .plan-header {
  border: 1px solid #ddd;
  border-right-color: transparent;
  border-bottom-color: transparent;
  background-color: RGB(221, 221, 221, 0.2);
  display: flex;
  flex-direction: column;
  align-content: center;
  vertical-align: top;
  position: relative;
  min-height: 185px;
  padding: 1.5rem;
  height: 180px;
  }
  .pricing-plans .plan .plan-header.first {
  border-color: transparent;
  background-color: transparent;
  }
  .pricing-plans .plan .plan-header.last {
  border-right: 1px solid #ddd;
  }
  .pricing-plans .plan .plan-header h3 {
  font-size: 2rem;
  margin: 0;
  text-align: center;
  }
  .pricing-plans .plan .plan-header h3 .label {
  display: block;
  font-size: 1rem;
  letter-spacing: 1px;
  margin: 0 auto 0px;
  text-transform: uppercase;
  font-weight: 700;
  }
  .pricing-plans .plan .plan-header h3 .figure {
  padding: 5px 0 5px;
  position: relative;
  }
  .pricing-plans .plan .plan-header h3 .figure span {
  display: inline-block;
  }
  .pricing-plans .plan .plan-header h3 .figure .amount {
  font-size: 4rem;
  font-weight: 700;
  letter-spacing: -3px;
  line-height: 1;
  }
  .pricing-plans .plan .plan-header h3 .figure .amount:before {
  content: "$";
  font-size: 1.3rem;
  font-weight: 400;
  vertical-align: top;
  letter-spacing: normal;
  }
  .pricing-plans .plan .plan-header h3 .figure .amount:after {
  position: relative;
  top: -6px;
  content: "/MO";
  letter-spacing: normal;
  font-weight: 400;
  vertical-align: bottom;
  font-size: 0.8rem;
  text-transform: uppercase;
  }
  .pricing-plans .plan .plan-header h3 .foreword {
  display: block;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0;
  }
  .pricing-plans .plan .plan-header h3 .afterword {
  font-size: 0.7rem;
  text-transform: uppercase;
  padding: 0;
  white-space: nowrap;
  color: #84949c;
  letter-spacing: 0px;
  }
  .pricing-plans .plan .plan-header h3 .text {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0px;
  text-align: left;
  }
  .pricing-plans .plan .plan-header .button {
  margin-top: auto;
  display: block;
  }
  .pricing-plans .plan .plan-info .list-group .list-group-item {
  border-radius: 0px;
  border-width: 1px;
  border-right-color: transparent;
  font-weight: 400;
  color: #000;
  }
  @media only screen and (min-width: 1200px) {
  .pricing-plans .plan .plan-info .list-group.first {
    margin-top: 11.75rem;
  }
  }
  
  .pricing-plans .plan .plan-info .list-group.first .list-group-item {
  border-color: transparent;
  padding: 0.9rem 1rem;
  }
  .pricing-plans .plan .plan-info .list-group.last .list-group-item {
  border-right-color: #ddd;
  }
  .billed-annually {
    background: rgb(24 37 74 / 10%);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 15px;
    border-right: 1px solid #fff;
  }
  .pricing-plans .plan:nth-child(2) {
    width: 30%;
   }
  .plan-collapse-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .plan-collapse-inner {
    display: flex;
    overflow: hidden;
  }
  .plans-wrapper-main {
    display: inline-block;
    width: 100%;
    padding-top: 80px;
    background-color: #ededf4;
    text-align: center;
    padding-bottom: 80px;
  }
  
  .plans-wrapper-main h2 {
    font-size: 32px;
    line-height: 58px;
    color: #232333;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 20px;
  }
.plan-collapse-inner {
    background: #fff;
    margin-top: 50px;
    position: relative;
   }
  
  .availability-wrapper {
    padding: 10px 14px;
    background: rgb(24 37 74 / 6%);
    color: #333;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-align: center;
   }
  .inner-header * {
    text-align: left;
    color: #000 !important;
}

.inner-header .container {
    width: 1600px;
    max-width: 100%;
}
.inner-header .inner-header-caption .container {
    max-width: 1406px;
}

.inner-header ul {
    padding: 0;
    display: flex;
    margin-top: 35px;
}

.inner-header ul a {
    width: 150px;
    display: inline-block;
    text-align: center;
    background-color: #b40f23;
    margin-right: 15px;
    padding: 10px 15px;
    color: #fff !important;
    border-radius: 5px;
    border: 2px solid #b40f23;
    font-weight: 500;
    transition: all 0.5s;
}

.inner-header ul li:first-child a {
    background: #f8f8f8 !important;
    color: #000 !important;
    border: 2px solid #18254a;
}

body .inner-header ul a:hover {
    background: #18254a !important;
    color: #fff !important;
    border-color: #18254a !important;
}
.col-md-6.text-left.email-wrapper {
    display: none;
}
.main-content .container {
  max-width: 1540px;
    width: 100%;
}
.pricing-plans .plan-number-wrapper {
    width: 5%;
}
.pricing-plans .plan {
    width: 90%;
    margin: 0 auto;
    min-width: 0;
    -webkit-box-shadow: 0 3px 0px 0 rgb(0 0 0 / 8%);
    box-shadow: 0 3px 0px 0 rgb(0 0 0 / 8%);
    transition: all 0.3s ease-in-out;
    padding: 2.25rem 0;
    position: relative;
    will-change: transform;
    text-align: center;
}
@media only screen and (min-width: 1200px){
.pricing-plans .plan {
    width: 19%;
}

.pricing-plans .plan:before {
    position: relative;
    content: "";
    border: 0;
    width: 100%;
    display: block;
    padding: 10px 15px;
    height: 42px;
    border-right: 0 !important;
}
.billed-annually {
    background: #607d8b;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px 15px;
    border-right: 1px solid #ffffff2e;
}
.billed-annually p {
    width: 100%;
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.5px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.features-wrapper {
    height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    background-color: #f1f2f4;
}
.list-group {
    padding-left: 0;
    margin-bottom: 20px;
}
}
@media screen and (max-width: 1800px){
.pricing-plans .plan .plan-info .list-group .list-group-item {
    height: 56px;
}
.pricing-plans .plan .plan-info .list-group .list-group-item {
    border-radius: 0px;
    border-width: 1px;
    border-right-color: transparent;
    font-weight: 400;
    color: #000;
}
.features-wrapper.dark-bg + ul {
    text-align: left;
}
.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #ddd;
}
.list-group {
    padding-left: 0;
    margin-bottom: 20px;
}
.features-wrapper.dark-bg {
    background: #2196f3;
    color: #fff;
    border: 0 !important;
    font-weight: normal;
}

.plan-benifits ul li {
    width: 100%;
    padding: 6px 16px;
    text-align: center;
    border-radius: 0 !important;
    letter-spacing: 0.5px;
    font-size: 16px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

ul.list-group.text-left li {
    text-align: left;
    padding-left: 40px;
    font-size: 16px;
    justify-content: flex-start;
}

li.list-group-item:nth-child(even) {
    background: #f8f8f8;
}
.text-center-position {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    width: 100%;
    padding: 0 25px;
}
li.list-group-item .fa-check {
    color: #4caf50;
}

li.list-group-item .fa-minus {
    opacity: 0.3;
}
.billed-annually p small {
    color: #ffffff82;
    line-height: 1;
    padding: 0 9px;
}

.plan.main-text-wrapper .billed-annually p {
    justify-content: flex-start !important;
    padding: 0 25px;
}
}