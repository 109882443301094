.badges-container {
    margin-top: 8px;
        text-align: left;
}

.batchesBadge{
    color: #333;
    padding: 4px 8px;
    border-radius: 16px;
    background: #ecf4ff;
    position: relative;
    font-size: 16px;
    font-weight: 500;
}

.filters-wrap {
    padding-top: 32px;
}

.filters-wrap ul {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    margin-bottom: 0;
}

.filter-list li {
    padding: 6px 16px;
    display: inline-block;
    border: 1px solid #828282;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #333;
    margin-right: 12px;
}

.filter-list {
    margin-top: 10px;
}

.batch-cards ul {
    margin-top: 16px;
}

.batch-cards ul li {
    margin-bottom: 16px;
}

.batch-cards .info-wrap {
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #fff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, .08);
    padding: 8px 12px;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
}

.batch-cards .info-wrap h4 {
    color: #333;
    line-height: normal;
}

.batch-cards .info-wrap h4 label {
    display: inline-block;
    width: auto;
    vertical-align: middle;
    background: #c8fbdd;
    border-left: 2px solid #27ae60;
    padding: 2px 8px;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0;
    position: relative;
    top: -1px;
    margin-left: 8px;
    border-radius: 0px 2px 2px 0px;
}

.batch-cards .info-wrap p {
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    color: #333;
    margin-top: 4px;
}







@media (min-width: 1200px) {
    .batch-cards ul .info-wrap .info {
        /* width: calc(100% - 373px); */
    }
}

@media (min-width: 992px) {
    .batch-cards ul .info-wrap .info {
        /* width: calc(100% - 340px); */
        min-height: 76px;
        margin-bottom: 0;
    }

    .batch-cards ul .info-wrap div {
        display: inline-block;
        vertical-align: middle;
    }

    .batch-cards ul .info-wrap h4 {
        font-size: 20px;
    }

    .batch-cards ul .info-wrap p {
        font-size: 14px;    
        padding-top: 3px;
    }

    
}