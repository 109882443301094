/* =========FormPage======= */
.request-demo{
    background: linear-gradient(to right, #6B71EC,#7FA7E9,#8DCFE7);
    /* background: #405189; */
    color: #fff;
}
.request-form{
    background-color: #ffffffd0;
}
label{
    color: #5f5f5f;
    font-size: 18px;
}
.request-demo-form input,textarea{
    padding: 10px ;
    color: #c7c5c5;
    border: 1px solid #dadada;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-top: 5px;
    
}
form span{
    color: red;
}

/* =========GlobalBrand======= */
.global-image-container{
    height: 50px;
    width: 100px;
}
/* =========why-use======= */

.why-use{
    background-color: #DCEBFF;
}
.why-use-para h5{
    font-size: 1.2rem;
}

.why-use-card {
    height: 250px;
}
.card-data-img{
    height: 50px;
    width: 50px;
}
/* ==========Marketing-campaing====== */
.marketing-camp{
    background-color: #E6F2FF;
}
.marketing-camp-head{
    width: 70%;
}
@media only screen and (max-width: 768px){
    .marketing-camp-head{
        width: 100%;
    }

    .why-use-card {
        height: 210px;
    }
   }

.automated-market{
    border: 1px solid #aec6f1;
    border-radius:10px ;
    background-color: #E2ECFE;
    color: #000;
    /* height: 100px; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}
.market-img{
    height: 270px;

}
/* ==========Leading====== */
.leading-cards{
    height: 320px;
    border: 2px solid #000;
    box-shadow: 10px 10px 0px #ECB16D;
}