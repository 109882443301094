@media only screen and (min-width: 576px) {
    .carousel-container{
     height: 70vh;
    }

    .carousel-image {
        height: 300px;
    }
   }
@media only screen and (max-width: 576px) {
    .carousel-container{
     height: 70vh;
    }
    

    .carousel-image {
        height: 190px;
        width: 85%;
    }

    .image-carousel {
        align-items: start !important;
    }
   }

@media only screen and (max-width: 390px) {
    .carousel-container{
     height: 80vh;
    }
    

    .carousel-image {
        height: 190px;
        width: 85%;
    }

    .image-carousel {
        align-items: start !important;
    }
   }