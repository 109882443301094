.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hover-list{
  font-size: 14px;
  cursor: pointer;
  color: #000;
  white-space: wrap;  
}
.hover-list:hover{
  color: #dc3545;
}


/* Example CSS to customize Google Translate widget */

/* Change background color of the dropdown */
.goog-te-combo {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
}

/* Change text color of the dropdown */
.goog-te-combo-option {
  color: #333;
}

/* Change font size and family */
.goog-te-combo {
  font-size: 14px;
  font-family: Arial, sans-serif;
}

/* Adjust dropdown arrow style */
.goog-te-button {
  background-color: #4285f4 !important; /* Change to Google blue */
  border-radius: 4px;
}

/* Change arrow color */
.goog-te-button img {
  filter: invert(100%);
}

/* Adjust hover and active states */
.goog-te-combo:hover, .goog-te-combo:focus {
  background-color: #e0e0e0;
  border-color: #aaa;
}
