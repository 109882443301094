@font-face {
    font-family: "Flaticon";
    src: url("../fonts/flaticon.eot");
    src: url("../fonts/flaticond41d.eot?#iefix") format("embedded-opentype"),
         url("../fonts/flaticon.woff") format("woff"),
         url("../fonts/flaticon.ttf") format("truetype"),
         url("../fonts/flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
      font-family: "flaticon";
      src: url("../fonts/flaticon8953.ttf?9da6da3c6ae6f38ca4698081ef934ea0") format("truetype"),
  url("../fonts/flaticon8953.woff?9da6da3c6ae6f38ca4698081ef934ea0") format("woff"),
  url("../fonts/flaticon8953.woff2?9da6da3c6ae6f38ca4698081ef934ea0") format("woff2"),
  url("../fonts/flaticon8953.eot?9da6da3c6ae6f38ca4698081ef934ea0#iefix") format("embedded-opentype"),
  url("../fonts/flaticon8953.svg?9da6da3c6ae6f38ca4698081ef934ea0#flaticon") format("svg");
  } 
  
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: "Flaticon";
      src: url("../fonts/flaticon.svg#Flaticon") format("svg");
    }
  }
  
  [class^="flaticon-"]:before, [class*=" flaticon-"]:before,
  [class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
      font-family: Flaticon;
      font-style: normal;
  }
  
  .flaticon-placeholder:before { content: "\f100"; }
  .flaticon-phone-call:before { content: "\f101"; }
  .flaticon-timetable:before { content: "\f102"; }
  .flaticon-unlink:before { content: "\f103"; }
  .flaticon-play-arrow:before { content: "\f104"; }
  .flaticon-money-bag:before { content: "\f105"; }
  .flaticon-trophy:before { content: "\f106"; }
  .flaticon-magnifying-glass:before { content: "\f107"; }
  .flaticon-menu:before { content: "\f108"; }
  .flaticon-shopping-cart:before { content: "\f109"; }
  .flaticon-settings:before { content: "\f10a"; }
  .flaticon-share:before { content: "\f10b"; }
  .flaticon-headphones:before { content: "\f10c"; }
  .flaticon-garbage:before { content: "\f10d"; }
  .flaticon-shopping-cart-1:before { content: "\f10e"; }
  .flaticon-email:before { content: "\f10f"; }
  .flaticon-message:before { content: "\f110"; }
  .flaticon-full-screen:before { content: "\f111"; }
  .flaticon-multiply:before { content: "\f112"; }
  .flaticon-media-play-symbol:before { content: "\f113"; }
  .flaticon-arrow-pointing-to-right:before { content: "\f114"; }
  .flaticon-back:before { content: "\f115"; }
  .flaticon-pdf:before { content: "\f116"; }
  .flaticon-loupe:before { content: "\f117"; }
  .flaticon-menu-1:before { content: "\f118"; }
  .flaticon-big-anchor:before { content: "\f119"; }
  .flaticon-tick:before { content: "\f11a"; }
  .flaticon-email-1:before { content: "\f11b"; }
  .flaticon-placeholder-1:before { content: "\f11c"; }
  .flaticon-home-page:before { content: "\f11d"; }
  .flaticon-safe:before { content: "\f11e"; }
  .flaticon-paper-plane:before { content: "\f11f"; }
  .flaticon-smartphone:before { content: "\f120"; }
  .flaticon-edit:before { content: "\f121"; }
  .flaticon-plus-symbol:before { content: "\f122"; }
  .flaticon-user:before { content: "\f123"; }
  .flaticon-checked:before { content: "\f124"; }
  .flaticon-menu-2:before { content: "\f125"; }
  .flaticon-search:before { content: "\f126"; }
  .flaticon-plus:before { content: "\f127"; }
  .flaticon-maps-and-flags:before { content: "\f128"; }
  .flaticon-email-2:before { content: "\f129"; }
  .flaticon-user-1:before { content: "\f12a"; }
  .flaticon-tick-1:before { content: "\f12b"; }
  .flaticon-right-arrow:before { content: "\f12c"; }
  .flaticon-left-arrow:before { content: "\f12d"; }
  .flaticon-call:before { content: "\f12e"; }
  .flaticon-comment:before { content: "\f12f"; }
  .flaticon-left-quote:before { content: "\f130"; }
  .flaticon-blocks-with-angled-cuts:before { content: "\f131"; }
  .flaticon-straight-quotes:before { content: "\f132"; }
  .flaticon-left-arrow-1:before { content: "\f133"; }
  .flaticon-right-arrow-1:before { content: "\f134"; }
  .flaticon-down-arrow:before { content: "\f135"; }
  .flaticon-user-2:before { content: "\f136"; }
  .flaticon-chat-bubble:before { content: "\f137"; }
  .flaticon-call-1:before { content: "\f138"; }
  .flaticon-quote:before { content: "\f139"; }
  .flaticon-quote-1:before { content: "\f13a"; }
  .flaticon-down-arrow-1:before { content: "\f13b"; }
  .flaticon-time:before { content: "\f13c"; }
  .flaticon-play:before { content: "\f13d"; }
  .flaticon-check:before { content: "\f13e"; }
  .flaticon-check-1:before { content: "\f13f"; }
  .flaticon-close:before { content: "\f140"; }
  .flaticon-call-2:before { content: "\f141"; }
  .flaticon-trash:before { content: "\f142"; }
  .flaticon-check-2:before { content: "\f143"; }
  .flaticon-call-3:before { content: "\f144"; }
  .flaticon-send:before { content: "\f145"; }
  .flaticon-big-envelope:before { content: "\f146"; }
  .flaticon-email-3:before { content: "\f147"; }
  .flaticon-play-button:before { content: "\f148"; }
  .flaticon-play-1:before { content: "\f149"; }
  .flaticon-worldwide:before { content: "\f14a"; }
  .flaticon-trophy-1:before { content: "\f14b"; }
  .flaticon-quotation:before { content: "\f14c"; }
  .flaticon-sketch:before { content: "\f14d"; }
  .flaticon-magnifying-glass-1:before { content: "\f14e"; }
  .flaticon-avatar:before { content: "\f14f"; }
  .flaticon-heart:before { content: "\f150"; }
  .flaticon-like:before { content: "\f151"; }
  .flaticon-back-1:before { content: "\f152"; }
  .flaticon-chat:before { content: "\f153"; }
  .flaticon-shopping-bag:before { content: "\f154"; }
  .flaticon-cancel:before { content: "\f155"; }
  .flaticon-download:before { content: "\f156"; }
  .flaticon-next:before { content: "\f157"; }
  .flaticon-house:before { content: "\f158"; }
  .flaticon-link:before { content: "\f159"; }
  .flaticon-padlock:before { content: "\f15a"; }
  .flaticon-chat-1:before { content: "\f15b"; }
  .flaticon-envelope:before { content: "\f15c"; }
  .flaticon-home:before { content: "\f15d"; }
  .flaticon-placeholder-2:before { content: "\f15e"; }
  .flaticon-megaphone:before { content: "\f15f"; }
  .flaticon-eye:before { content: "\f160"; }
  .flaticon-play-button-1:before { content: "\f161"; }
  .flaticon-padlock-1:before { content: "\f162"; }
  .flaticon-planet-earth:before { content: "\f163"; }
  .flaticon-chat-2:before { content: "\f164"; }
  .flaticon-magnifying-glass-2:before { content: "\f165"; }
  .flaticon-quote-2:before { content: "\f166"; }
  .flaticon-next-1:before { content: "\f167"; }
  .flaticon-cancel-1:before { content: "\f168"; }
  .flaticon-menu-3:before { content: "\f169"; }
  .flaticon-map:before { content: "\f16a"; }
  .flaticon-location-pin:before { content: "\f16b"; }
  .flaticon-notepad:before { content: "\f16c"; }
  .flaticon-web-search-engine:before { content: "\f16d"; }
  .flaticon-chat-bubbles-with-ellipsis:before { content: "\f16e"; }
  .flaticon-angle-arrow-pointing-to-right:before { content: "\f16f"; }
  .flaticon-wall-clock:before { content: "\f170"; }
  .flaticon-cart:before { content: "\f171"; }
  .flaticon-soccer-cup:before { content: "\f172"; }
  .flaticon-badge:before { content: "\f173"; }
  .flaticon-conversation:before { content: "\f174"; }
  .flaticon-chat-3:before { content: "\f175"; }
  .flaticon-pdf-1:before { content: "\f176"; }
  .flaticon-clock:before { content: "\f177"; }
  .flaticon-pin:before { content: "\f178"; }
  .flaticon-menu-4:before { content: "\f179"; }
  .flaticon-send-1:before { content: "\f17a"; }
  .flaticon-start-up:before { content: "\f17b"; }
  .flaticon-next-2:before { content: "\f17c"; }
  .flaticon-left-arrow-2:before { content: "\f17d"; }
  .flaticon-long-arrow-pointing-to-the-right:before { content: "\f17e"; }
  .flaticon-right-arrow-3:before { content: "\f17f"; }
  .flaticon-up-arrow:before { content: "\f180"; }
  .flaticon-quote-3:before { content: "\f181"; }
  .flaticon-shopping-bag-1:before { content: "\f182"; }
  .flaticon-verify:before { content: "\f183"; }
  .flaticon-heart-box:before { content: "\f184"; }
  .flaticon-magic:before { content: "\f185"; }
  .flaticon-check-3:before { content: "\f186"; }
  .flaticon-double-check:before { content: "\f187"; }
  .flaticon-right-arrow-2:before { content: "\f188"; }
  .flaticon-filter-filled-tool-symbol:before { content: "\f189"; }
  .flaticon-squares:before { content: "\f18a"; }
  .flaticon-grid:before { content: "\f18b"; }
  .flaticon-list:before { content: "\f18c"; }
  .flaticon-list-1:before { content: "\f18d"; }
  .flaticon-list-2:before { content: "\f18e"; }
  .flaticon-play-button-2:before { content: "\f18f"; }
  .flaticon-microsoft-word-document-file:before { content: "\f190"; }
  .flaticon-file:before { content: "\f191"; }
  .flaticon-check-4:before { content: "\f192"; }
  .flaticon-like-1:before { content: "\f193"; }
  .flaticon-dislike:before { content: "\f194"; }
  .flaticon-homework:before { content: "\f195"; }
  .flaticon-calendar:before { content: "\f196"; }
  .flaticon-user-3:before { content: "\f197"; }
  .flaticon-customer:before { content: "\f198"; }
  .flaticon-file-1:before { content: "\f199"; }
  .flaticon-eye-1:before { content: "\f19a"; }
  .flaticon-bookmark:before { content: "\f19b"; }
  .flaticon-bullhorn:before { content: "\f19c"; }
  .flaticon-cyclist:before { content: "\f19d"; }
  .flaticon-bar-chart:before { content: "\f19e"; }
  .flaticon-software:before { content: "\f19f"; }
  .flaticon-atom:before { content: "\f1a0"; }
  .flaticon-webpage:before { content: "\f1a1"; }
  .flaticon-language:before { content: "\f1a2"; }
  .flaticon-team:before { content: "\f1a3"; }
  .flaticon-healthcare:before { content: "\f1a4"; }
  .flaticon-united:before { content: "\f1a5"; }
  .flaticon-unity:before { content: "\f1a6"; }
  .flaticon-team-1:before { content: "\f1a7"; }
  .flaticon-teaching:before { content: "\f1a8"; }
  .flaticon-mortarboard:before { content: "\f1a9"; }
  .flaticon-graduation-cap:before { content: "\f1aa"; }
  .flaticon-notebook:before { content: "\f1ab"; }
  .flaticon-certificate:before { content: "\f1ac"; }
  .flaticon-languages:before { content: "\f1ad"; }
  .flaticon-reading:before { content: "\f1ae"; }
  .flaticon-open-book:before { content: "\f1af"; }
  .flaticon-pencil-and-ruler:before { content: "\f1b0"; }
  .flaticon-clipboard:before { content: "\f1b1"; }
  .flaticon-edit-1:before { content: "\f1b2"; }
  .flaticon-education:before { content: "\f1b3"; }
  .flaticon-pencil:before { content: "\f1b4"; }
  
  
  
  i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
      font-family: flaticon !important;
      font-style: normal;
      font-weight: normal !important;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }
  
  .flaticon-shopping-cart:before {
      content: "\f101";
  }
  .flaticon-favorites:before {
      content: "\f102";
  }
  .flaticon-atom:before {
      content: "\f103";
  }
  .flaticon-left-quotes-sign:before {
      content: "\f104";
  }
  .flaticon-phone-call:before {
      content: "\f105";
  }
  .flaticon-open-mail:before {
      content: "\f106";
  }
  .flaticon-placeholder:before {
      content: "\f107";
  }
  .flaticon-loupe:before {
      content: "\f108";
  }
  .flaticon-mail:before {
      content: "\f109";
  }
  