
/* ======Header======= */
ul>li{
    list-style: none;
    color: #5c5b5b;
    font-weight: 600;
}
.join{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
 .join a{
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding: 0px 5px;

 }

.Btn{
    /* padding: 7px 24px; */
    height: 40px;
    width: 150px;
    margin: 4px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 23px;
    border: 1px solid transparent;
    background-color: transparent;
    color: #fff;

}
/* ======MainComponent======= */
.MainComponent{
    background-color: #405189;
}
/* .MaincomponentHead h1{
    font-size: 46px;
} */
.plan-pricing-select{
    width: auto;
}

.Btn1{
    font-size: 18px;
    border: 3px solide white;
    background-color: transparent;
    color: #fff;
    font-weight: 600;
    padding: 5px 20px;
    margin-bottom: 20px;
    border-radius: 20px;
}

/* ======Banner======= */

.Banner{
    background: linear-gradient(to right,#405189,#687ab8,#6d8df3);
}
@media only screen and (max-width: 992px){
    .banner-img{
        height: 300px
    }
    .story-img{
        /* position: relative; */
    }
    .atlassian img{
        margin-right: 20px;
     }
}
  
/* ======Nav Bar======= */

.navbar-section{
    height: 6vh;
    /* width: 100%; */
    background-color: #000;
    /* position: fixed; */
}
.navbar p, i{
    color: #fff;
    font-size: 18px;
    font-weight: 500;

}
.story-img{
    /* position: absolute; */
    height: 300px;
    /* border-radius: 20px; */
    /* top: -3%; */
    /* left: 8%; */
}




@media only screen and (max-width: 568px){
   
    .banner-img{
        height: 250px
    }
    .story-img{
        /* position: relative; */
        height: 250px;
    }
    .atlassian img{
        margin-right: 20px;
     }
 
}
 
.atlassian img{
    height: 45px;
}




/* =======Atlassian========= */

.logo-image{
 width: 150px;
 }
@media only screen and (max-width: 992px){
 .logo-image{
    width: 100px;
    height: 100px;
 }
}

/* text animation */

  
  .inner-headings {
    border: 0px solid #ddd;
    height: 50px;
    line-height: 50px;
    text-transform: capitalize;
    overflow: hidden;
  }
  
  .inner-headings span {
    position: relative;
    animation: animation 10s ease infinite;
  }
  
  @keyframes animation {
    0%,
    100% {
      top: 0;
    }
    20% {
      top: 0;
    }
    25% {
      top: -50px;
    }
    45% {
      top: -50px;
    }
    50% {
      top: -100px;
    }
    70% {
      top: -100px;
    }
    75% {
      top: -150px;
    }
    95% {
      top: -150px;
    }
  }
