

.page-pagination {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pagination li {
    margin: 0 5px;
  }
  
  .pagination button {
    background-color: #fff;
    /* border: 1px solid #dee2e6; */
    border: none;
    /* border-radius: 45%; */
    color: #495057;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .pagination button.active,
  .pagination button:hover {
    background-color: #103675;
    border-color: #103675;
    color: #fff;
  }
  
  .pagination button:disabled {
    background-color: #e9ecef;
    border-color: #e9ecef;
    color: #adb5bd;
    cursor: not-allowed;
  }
  